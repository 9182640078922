export function getServicePlatform() {
  const ua = navigator.userAgent
  if (ua.match(/MicroMessenger/i) == 'MicroMessenger') {
    return 3
  }
  if (ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return 2
  }
  if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
    return 1
  }
  return 5
}

export function removeRepeat(arr) {
  if (typeof arr[0] == 'object') {
    for (var i = 0; i < arr.length; i++) {
      arr[i] = JSON.stringify(arr[i])
    }
    arr = [...new Set(arr)]
    for (let i = 0; i < arr.length; i++) {
      arr[i] = JSON.parse(arr[i])
    }
  } else {
    arr = [...new Set(arr)]
  }
  return arr
}

export function difference(a, b) {
  const arr1 = new Set(b)
  const arr2 = new Set(a)
  return [...a.filter((x) => !arr1.has(x)), ...b.filter((x) => !arr2.has(x))]
}

// 节流
export const throttle = (fn, delay) => {
  let preivios = 0
  return function () {
    const now = +Date.now()
    if (now - preivios > delay) {
      fn.apply(this, arguments)
      preivios = now
    }
  }
}

// 防抖
export const debounce = (fn, delay) => {
  let timer
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

export const renameKeys = (keyMap, obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[keyMap[key] || key] = obj[key]
    return acc
  }, {})

/**
 * 浮点数精度计算
 */

// 判断是否整数
function _isInterger(num) {
  return Math.floor(num) === num
}

/**
 * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
 * @param { Number } floatNum  数字
 * @return { Object } {times:100, num: 314}
 *
 */
function _toInteger(floatNum) {
  // 默认值
  const res = { multiple: 1, num: 0 }
  // 如果为整数,则返回
  if (_isInterger(floatNum)) {
    res.num = floatNum
    return res
  }
  const str1 = floatNum + '' // 转变数字为字符串
  const dotPos = str1.indexOf('.') // 获取符号"."的位置
  const len = str1.substr(dotPos + 1).length // 获取小数点后面的长度
  const multiple = Math.pow(10, len) // 计算倍数
  const intNum = Number(str1.replace('.', '')) // 转变浮点数为整数
  res.multiple = multiple // 赋值倍数
  res.num = intNum // 赋值整数
  return res // 返回结果
}

// 计算
/**
 * 数字计算
 * @param {Number} num1 运算数1
 * @param {Number} num2 运算数2
 * @param {Number} digits 精度 保留的小数点位数,比如2,则保留小数点后两位
 * @param {String} type 运算类型 add相加 substract相减 mutiply相乘 divide相除
 */
function _operation(num1, num2, digits, type) {
  const o1 = _toInteger(num1) // 运算数1转整数
  const o2 = _toInteger(num2) // 运算数2转整数
  const n1 = o1.num // 获取运算数1转变后的整数值
  const n2 = o2.num // 获取运算数2转变后的整数值
  const m1 = o1.multiple // 获取运算数1转变后的倍数
  const m2 = o2.multiple // 获取运算数2转变后的倍数
  const max = Math.max(m1, m2) // 计算两个运算数的最大倍数
  let result = null // 初始化返回结果
  switch (type) {
    case 'add': // 加法
      if (m1 === m2) {
        result = n1 + n2
      } else if (m1 > m2) {
        result = n1 + n2 * (m1 / m2)
      } else if (m1 < m2) {
        result = n1 * (m2 / m1) + n2
      }
      result = result / max
      break
    case 'substract': // 减法
      if (m1 === m2) {
        result = n1 - n2
      } else if (m1 > m2) {
        result = n1 - n2 * (m1 / m2)
      } else if (m1 < m2) {
        result = n1 * (m2 / m1) - n2
      }
      result = result / max
      break
    case 'mutiply': // 乘法
      result = (n1 * n2) / (m1 * m2)
      break
    case 'divide': // 除法
      result = n1 / n2 / (m1 / m2)
      break
    default:
      break
  }
  // 精度值存在
  if (digits) result = parseFloat(result.toFixed(digits))
  return result
}

// 加法
export const add = (a, b, digits = false) => {
  return _operation(a, b, digits, 'add')
}

// 减法
export const substract = (a, b, digits = false) => {
  return _operation(a, b, digits, 'substract')
}

// 乘法
export const mutiply = (a, b, digits = false) => {
  return _operation(a, b, digits, 'mutiply')
}

// 除法
export const divide = (a, b, digits = false) => {
  return _operation(a, b, digits, 'divide')
}

// 深拷贝
export const deepClone = (obj) => {
  if (obj === null) return null
  const clone = Object.assign({}, obj)
  Object.keys(clone).forEach(
    (key) =>
    (clone[key] =
      typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  )
  return Array.isArray(obj) && obj.length
    ? (clone.length = obj.length) && Array.from(clone)
    : Array.isArray(obj)
      ? Array.from(obj)
      : clone
}

export function isIphoneX() {
  if (/iphone/gi.test(window.navigator.userAgent)) {
    /* iPhone X、iPhone XS */
    const x = window.screen.width === 375 && window.screen.height === 812
    /* iPhone XS Max */
    const xsMax = window.screen.width === 414 && window.screen.height === 896
    /* iPhone XR */
    const xR = window.screen.width === 414 && window.screen.height === 896
    if (x || xsMax || xR) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const getURLParameters = (url) => {
  if (!url) return {}
  return (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    // eslint-disable-next-line no-sequences
    (a, v) => (
      (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
    ),
    {}
  )
}

export const objToQueryStr = (obj) =>
  Object.keys(obj)
    .map((key) => key + '=' + obj[key] + '')
    .join('&')

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}


export function transformDateTime(time, format = 'YYYY-MM-DD HH:mm:ss') {
  let t = new Date(time)
  let tf = function (i) {
    return (i < 10 ? '0' : '') + i
  }
  return format.replace(/YYYY|MM|DD|HH|mm|ss/g, function (a) {
    switch (a) {
      case 'YYYY':
        return tf(t.getFullYear())
        break
      case 'MM':
        return tf(t.getMonth() + 1)
        break
      case 'mm':
        return tf(t.getMinutes())
        break
      case 'DD':
        return tf(t.getDate())
        break
      case 'HH':
        return tf(t.getHours())
        break
      case 'ss':
        return tf(t.getSeconds())
        break
    }
  })
}


export function geturlparam() {
  let that = this
  // window.location.href 获取地址
  let url = window.location.href
  console.log(url , 'url');
  let p = url.split('?')[1]
  let keyValue = p.split('&');
  let obj = {};
  for (let i = 0; i < keyValue.length; i++) {
    let item = keyValue[i].split('=');
    let key = item[0];
    let value = item[1];
    obj[key] = value;
  }
  return obj
  console.log(keyValue); //  ['type=1','id=2']
  console.log(obj);      //  {type:'1',id:'2'}
}
